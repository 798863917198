import { useMutation } from 'react-query'
import axios from 'axios'

const getQuery = (key, params) => {
	const path = key.charAt(0) === '/' ? key : `/${key}`

	return {
		queryKey: path,
		queryFn: async () => {
			const res = await axios.get(path, {
				headers: {
					'Accept': 'application/json',
					'Auth-Token': localStorage.token
				}
			})

			return {
				...Object.values(res.data)[0],
				type: Object.keys(res.data)[0]
			}
		}
	}
}

export const getOrganization = (id) => {
	return getQuery(`organizations/${id}`)
}


/*
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useCaromQuery } from '../../lib/useCaromQuery'
import { useResourceViewRecorder } from '../../lib/useResourceViewRecorder'

export const useOrganizationLoader = () => {
	const { id } = useParams()
	const { data: organization, loading, error } = useCaromQuery(`/organizations/${id}`)
	const recordView = useResourceViewRecorder('organizations')

	useEffect(() => {
		if (organization) {
			recordView(organization)
		}
	}, [organization])

	return [organization, loading, error]
}*/