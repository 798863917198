const PropTypes = require('prop-types');

export const FormInput = window.FormInput = {
	state: {
		invalid: false
	},
	contextTypes: {
		object: PropTypes.object,
		inputChangeHandler: PropTypes.func,
		valueFor: PropTypes.func,
		isDefaultValue: PropTypes.func,
		registerFormField: PropTypes.func
	},
	isFormInput: true,
	componentDidMount() {
		if (this.context.registerFormField) { this.context.registerFormField(this.props.name); }
	},
	componentWillReceiveProps(nextProps) {
		if (this.context.registerFormField) { this.context.registerFormField(nextProps.name); }
		this.updateValidity();
	},
	handleChange: function(name, e) {
		if (this.props.changeHandler) {
			this.props.changeHandler(name, e);
		}

		let res = this.context.inputChangeHandler(name, e);
		this.updateValidity();

		return res;
	},
	getFieldName: function() {
		return this.props.name;
	},
	updateValidity: function() {
		this.setState({
			invalid: this.invalid()
		});
	},
	invalid: function() {
		return this.context.object && this.context.object.saveHasBeenAttempted() && this.context.object.invalid() && this.context.object.fieldIsInvalid(this.props.name);
	},
	classes: function() {
		return (this.props.className || '') + ((this.state && this.state.invalid) ? ' error' : '');
	},
};