import React from 'react';
import { FormInput } from './FormInput';
import createReactClass from 'create-react-class';
import DatePicker from 'react-datepicker';
const { DateTime } = require("luxon");

export const DatePickerInput = createReactClass({
	mixins: [FormInput],

	getValue: function() {
		return this.selectedDate || (!this.context.valueFor(this.props.name) ? null : new Date(this.context.valueFor(this.props.name)));
	},
	onDateChange: function(date) {
		this.selectedDate = date;
		this.context.inputChangeHandler(this.props.name, date);
	},
	render: function() {
/*
console.log(
	this.context.valueFor(this.props.name),
	this.getValue(),
	DateTime.fromISO(this.context.valueFor(this.props.name)).toFormat('yyyy-MM-dd'),
	new Date(this.context.valueFor(this.props.name))
)
*/
		return <DatePicker
			{...this.props}
			id={this.props.name}
			onChange={this.onDateChange}
			selected={this.getValue()}
			dateFormat="yyyy-MM-dd"
		/>
	}
});