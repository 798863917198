import React, { useState, useEffect } from 'react';
import Task from '../../resources/Task';
import TaskRepository from '../../repositories/TaskRepository';
import MilestoneRepository from '../../repositories/MilestoneRepository';
import Assigner from './Assigner';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { Select } from '../../lib/forms/Select';
import { TextArea } from '../../lib/forms/TextArea';
import { DatePickerInput } from '../../lib/forms/DatePickerInput';
import { sortBy } from 'lodash';
import { useAuth } from '../../lib/useAuth'

const TaskForm = (props) => {
	const mobileDisposition = 'modal';
	const [task, setTask] = useState(props.task)
	const [specificDueDate, setSpecificDueDate] = useState(false)
	const [milestonesLoaded, setMilestonesLoaded] = useState(false)
	const [showAssigner, setShowAssigner] = useState((props.task?.assigned_user_ids && props.task.assigned_user_ids.length > 0))
	const [milestones, setMilestones] = useState([])
	const [submitting, setSubmitting] = useState(false)
	const auth = useAuth()

	useEffect(() => {
		const loadMilestones = () => {
			new MilestoneRepository().getCollection({ slice: 'active' }).then((collection) => {
				setMilestonesLoaded(true)
				setMilestones(sortBy(collection.records, 'name'))
			});
		}

		if (props.task) {
			setTask(task)
		} else {
			let recordAttributes = props.defaultParams || {};

			if (props.record) {
				recordAttributes[`${props.record.constructor.modelName.toLowerCase()}_id`] = props.record.id;
			}

			let milestoneAttributes = (props.milestone ? { milestone_id: props.milestone.id } : {});
			let defaultAttributes = Object.assign({}, recordAttributes, milestoneAttributes);

			setTask(new Task(defaultAttributes))
		}

		loadMilestones()
	}, [])


	/*constructor(props) {
		super(props);

		let task;
		if (props.task) {
			task = props.task;
			if (task.hasMilestone()) { task.milestone_id = task.milestone.id;}
		} else {
			let recordAttributes = props.defaultParams || {};

			if (props.record) {
				recordAttributes[`${props.record.constructor.modelName.toLowerCase()}_id`] = props.record.id;
			}

			let milestoneAttributes = (props.milestone ? { milestone_id: props.milestone.id } : {});
			let defaultAttributes = Object.assign({}, recordAttributes, milestoneAttributes);

			task = new Task(defaultAttributes);
		}

		loadMilestones();
	}*/

	const dueDatePicker = () => {
		if (specificDueDate || task.hasDueDate()) {
			return <DatePickerInput name="due" className="full small centered" placeholder="Due date" dateFormat="MMMM d, yyyy" autoFocus={specificDueDate} />
		} else {
			return (
				<div className="styled-select small">
					<Select name="due_shortcut" changeHandler={(_, e) => setSpecificDueDate(e.target.value === 'date')}>
						<option value="">No due date</option>
						<option value="today">Today</option>
						<option value="tomorrow">Tomorrow</option>
						<option value="this_week">This week</option>
						<option value="next_week">Next week</option>
						<option value="date">On&hellip;</option>
					</Select>
				</div>
			);
		}
	}

	const milestonePicker = () => {
		if (milestonesLoaded) {
			return (
				<div className="styled-select small">
					<Select defaultValue={task.hasMilestone() && task.milestone.id} name="milestone_id" key="milestones.loaded">
						<option value="">No milestone</option>
						{milestones.map(milestone => {
							return <option
								key={`task.milestone.${milestone.id}`}
								value={milestone.id}
							>{milestone.name}</option>
						})}
					</Select>
				</div>
			);
		} else {
			return (
				<div className="styled-select small">
					<Select name="milestone_id">
						{task.hasMilestone() ? <option value={task.milestone.id}>{task.milestone.name}</option> : <option value="">No milestone</option>}
						<optgroup label="Loading other milestones"></optgroup>
					</Select>
				</div>
			);
		}
	}

	const associatedRecord = () => {
		switch (task.associatedRecordType()) {
			case 'contact':
				return <span>{task.contact.displayName()}</span>;
			case 'file':
				return <span>{task.file.displayName()}</span>;
			case 'organization':
				return <span>{task.organization.displayName()}</span>;
			default:
				return <span>No associated record</span>;
		}
	}

	const setAssignment = (users) => {
		let updatedTask = task;
		updatedTask.assigned_user_ids = users.map(user => user.id);

		setTask(updatedTask)
	}

	const responsibilityRow = () => {
		if (auth.account.multiUser()) {
			return (
				<div className="responsibility">
					<i className="fal fa-user" />
					<span className={"text-button" + (showAssigner ? " hidden" : "")} onClick={() => setShowAssigner(true)}>Assign this task</span>
					<div className={"assigner" + (showAssigner ? "" : " hidden")}>
						<Assigner
							placeholder="Assign to..."
							record={task}
							userLookup={task.assignees.bind(task)}
							account={auth.account}
							setChosenUsers={setAssignment}
						/>
					</div>
				</div>
			);
		}
	}

	const showLoaded = () => {
		return (
			<Form repository={new TaskRepository()} object={task} beforeSubmit={() => setSubmitting(true)} onSave={props.onSave} router={props.router}>
				<div className={"view-task edit" + (task.exists() ? ' existing' : '')}>
					<h2>
						<TextInput name="assignment" placeholder="Task or assignment" autoFocus />
						 <i className={"fa fa-pencil edit" + (props.toggleForm ? ' active' : '')} onClick={props.toggleForm} />
					</h2>
					{responsibilityRow()}
					<div className="meta">
						<div className={"due" + (!task.hasDueDate() ? ' none' : '')}>
							{dueDatePicker()}
						</div>
						<div className={"milestone" + (!task.hasMilestone() ? ' none' : '')}>
							{milestonePicker()}
						</div>
						{task.exists() ?
							<div className={"record " + (task.hasAssociatedRecord() ? task.associatedRecordType() : 'none')}>
								{associatedRecord()}
							</div>
							: null
						}
					</div>
					<div className="main-content">
						<div className="description">
							<TextArea name="description" placeholder="Your description of this task: what it is, how to do it, why it matters" className="four-lines full" />
						</div>
						<div className="submit">
							{
								submitting
								? <i className="fa fa-spinner fa-spin" />
								: <input type="submit" value="Save this task" className="green rounded medium" />
							}
						</div>
					</div>
				</div>
			</Form>
		)
	}

	return task ? showLoaded() : null
}

export default TaskForm