import Resource from '../lib/Resource';
import { includes, union, pull } from 'lodash';
import AsyncFetcher from '../lib/AsyncFetcher';
import ViewHistory from '../lib/ViewHistory';
import { intersection } from 'lodash';

export default class User extends Resource {
	isAdmin() {
		return this.is_admin;
	}

	isOwner() {
		return this.is_owner;
	}

	canConnectMailbox() {
		return this.can_connect_email_account;
	}

	isCollaborator() {
		return !this.canConnectMailbox();
	}

	displayName() {
		return this.first_name + ' ' + this.last_name;
	}

	getInitials() {
		if (this.first_name && this.last_name) {
			return this.first_name.charAt(0) + this.last_name.charAt(0)
		} else {
			return "";
		}
	}

	scopeFor(resource) {
		switch (resource) {
			case 'contacts':
				return localStorage.addressBookScope || 'global';
			default:
				return null;
		}
	}

	requiresTwoFactorAuthentication() {
		return (this.configuration && this.configuration.second_factor_enabled);
	}

	owns(record) {
		if (record.mailbox_id) {
			return this.isPersonalMailbox(record.mailbox_id);
		} else if (record.mailboxes) {
			return intersection(record.mailboxes.map(box => box.id), this.subscribedMailboxIds()).length > 0;
		} else if (record.mailbox_ids) {
			return intersection(record.mailbox_ids, this.subscribedMailboxIds()).length > 0;
		}
	}

	cacheViewHistory() {
		localStorage.setItem('viewHistory', JSON.stringify(this.view_history));
	}

	recordRecordView(record, type) {
		this.getViewHistory().addRecord(record, type)
	}

	getViewHistory() {
		if (!this.viewHistory) { this.viewHistory = new ViewHistory(this); }

		return this.viewHistory;
	}

	subscribesTo(user) {
		return includes(this.subscribed_user_ids, user.id);
	}

	subscribeTo(user, callback = function(){}) {
		this.set('subscribed_user_ids', union(this.subscribed_user_ids, [user.id]));
		this.save(callback);
	}

	unsubscribeFrom(user, callback = function(){}) {
		this.set('subscribed_user_ids', pull(this.subscribed_user_ids, user.id));
		this.save(callback);
	}

	getMailboxes() {
		if (!this.mailboxes) {
			this.mailboxes = this.mailbox_subscriptions.map(sub => sub.mailbox);
		}

		return this.mailboxes;
	}

	getMailboxSubscriptions() {
		return this.mailbox_subscriptions
	}

	isFirstUser() {
		return !this.account || !this.account.id;
	}

	toggleSubscriptionTo(user, callback) {
		if (this.subscribesTo(user)) {
			this.unsubscribeFrom(user, callback);
		} else {
			this.subscribeTo(user, callback);
		}
	}

	canEdit(record) {
		return record.user_id === this.id
	}

	roleName() {
		if (this.is_owner) {
			return 'Owner';
		} else if (this.is_admin) {
			return 'Administrator';
		} else {
			return 'User'
		}
	}

	isPersonalMailbox(mailbox) {
		if (typeof mailbox === 'string') {
			mailbox = this.account.getMailboxById(mailbox);
		}

		return mailbox.email_address === this.email_address;
	}

	getMailboxById(id) {
		return this.getMailboxes().find(mailbox => mailbox.id === id);
	}

	primaryMailbox() {
		return this.getMailboxes().find(mailbox => mailbox.email_address === this.email_address);
	}

	hasPrimaryMailbox() {
		return !!this.primaryMailbox();
	}

	primaryMailboxId() {
		if (this.hasPrimaryMailbox()) {
			return this.primaryMailbox().id;
		} else { return null; }
	}

	subscribedMailboxCount() {
		return this.mailbox_subscriptions.length;
	}

	subscribedMailboxIds() {
		return (this.mailbox_subscriptions || []).map(sub => sub.mailbox_id);
	}


	isSubscribedTo(mailbox) {
		return this.subscribedMailboxIds().includes(mailbox.id);
	}

	hasBeenActivated() {
		return this.activated || this.activated_at
	}
}

User.loadFromToken = async (id) => {
	const fetcher = new AsyncFetcher(localStorage.token)
	let json = await fetcher.get('/users/current')

	return new User(json.user);
}

User.fields = [
	'id',
	'first_name',
	'last_name',
	'email_address',
	'title',
	'background',
	'password',
	'password_confirmation',
	'is_admin',
	'is_owner',
	'can_connect_email_account',
	'subscribed_user_ids',
	'activation_code',
	'activated',
	'activated_at',
	'initialized',
	'session',
	'deleted',
	'unread_open_thread_count',
	'inbox_last_viewed_at',
	{ avatar: 'Avatar' },
	{ 'mailbox_subscriptions': ['MailboxSubscription'] },
	{ account: 'Account' },
	{ configuration: 'Configuration' },
	'view_history',
	{ notification_inbox: 'NotificationInbox' }
];
User.editableFields = [
	'first_name', 'last_name', 'background', 'configuration', 'avatar', 'is_admin', 'is_owner', 'password',
	'password_confirmation', 'mailbox_subscriptions', 'email_address', 'can_connect_email_account'
]
User.identifiableBy = ['id', 'activation_code'];
User.validations = {
	first_name: { test: 'present', if: function(user) { return !user.isFirstUser() } },
	last_name: { test: 'present', if: function(user) { return !user.isFirstUser() } },
	email_address: { test: /^[^@]+@[^@]+\.[^@]+$/i },
	password: { test: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\s\d^$@()<>[\].,{}\-_+|=$!%*#?&]{8,}$/, if: function(user) { return user.password && user.activation_code } },
//	password_confirmation: { test: function(user) { return user.password === user.password_confirmation }, if: function(user) { return user.password && user.password_confirmation } }
}
User.url = '/users';
User.modelName = 'User';