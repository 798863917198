import React from 'react';
import Loading from '../../common/Loading';
import FileRepository from '../../../repositories/FileRepository';
import { useAuth, useRouter } from '../../../lib/hooks';
import Table from '../../files/Table';
import NoResults from '../../common//NoResults';
import { FilterableRecordCollection } from '../../common/FilterableRecordCollection';

const Files = (props) => {
	const auth = useAuth()
	const router = useRouter()

	const table = () => {
		if (props.records.length > 0) {
			return <Table files={props.records} location={props.location} router={router} />
		} else if (props.activeFilters === []) {
			return <NoResults message={`You have not exchanged any files with ${props.contact.displayName()}`} />;
		} else {
			return <NoResults message="There are no files matching your filter criteria" />;
		}
	}

	const handleSearchFieldUpdate = (e) => {
		if (e.key === 'Enter') {
			props.toggleFilter('query', e.target.value);
		}
	}

	if (!props.loaded) { return <Loading inline={true} />; }

	return (
		<div className="files panel">
			<div className="panel-filters">
				<div className="main-search">
					<i className="fa fa-search intro-icon" />
					<input type="text" placeholder="Search by file name" onKeyUp={handleSearchFieldUpdate} />
				</div>

				<div className="spacer"></div>

				<div className="extra-options">
					<div className="filter-buttons">
						<div className="styled-select small">
							<select className="small" onChange={(e) => { props.toggleFilter('type', e.target.value) }}>
								<option value="">Any file type</option>
								<option value="images">Images</option>
								<option value="documents">Document</option>
								<option value="spreadsheets">Spreadsheets</option>
								<option value="presentations">Presentations</option>
								<option value="pdfs">PDFs</option>
								<option value="other">Other file types</option>
							</select>
						</div>
						<div className="styled-select small">
							<select className="small" onChange={(e) => { props.toggleFilter('sender', e.target.value) }}>
								<option value="">Any sender</option>
								<option value={props.currentUser.email_address}>From you</option>
								<option value={props.contact.email_address}>From {props.contact.displayShortName()}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div>
				{table()}
			</div>
		</div>
	)
}

export default Files

/*
export default FilterableRecordCollection(CurrentUserAccessor(Files), FileRepository, {
	query: (file, query) => {
		if (query === '') {
			return true;
		} else {
			return (file.name || '').toLowerCase().includes(query.toLowerCase()) || (file.file_name || '').toLowerCase().includes(query.toLowerCase());
		}
	},
	type: (file, type) => { return file.typeCategory() === (type.slice(-1) === 's' ? type.slice(0, -1) : type) },
	sender: (file, email_address) => {
		return file.sender_email_addresses.includes(email_address)
	}
});
*/