import { useState } from 'react'
import { Form } from '../../lib/forms/Form'
import { TextInput } from '../../lib/forms/TextInput'
import { TextArea } from '../../lib/forms/TextArea'
import ContactRepository from '../../repositories/ContactRepository'
import Contact from '../../resources/Contact'
import Dropzone, { useDropzone } from 'react-dropzone'
import { useRouter } from '../../lib/hooks'
import FileUploader from '../../lib/FileUploader'
import { EmploymentInput, LocationInput, SocialProfileInput, WebsiteInput, EmailAddressInput, Section } from './contact/form';

const ContactForm = (props) => {
	const [avatar, setAvatar] = useState(null)
	const [contact, setContact] = useState(props.contact)
	const [uploadInProgress, setUploadInProgress] = useState(false)
	const [errors, setErrors] = useState([])
	const router = useRouter()

	const onDrop = files => {
		setUploadInProgress(true)

		new FileUploader('avatars', (file) => {
			const avatar = contact.getOrBuildAssociation('avatar')
			avatar.loadAttributes({
				source_url: `https://s3.amazonaws.com/carom.avatars/${file.s3_key}`,
				file_name: file.file_name
			});

			setUploadInProgress(false)
			setAvatar(files[0])
			setContact(new Contact({ ...contact, ...avatar }))
		}).upload(files[0])
	}

	const handleError = () => {
		setErrors(['s'])
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	const showAvatar = () => {
		if (uploadInProgress) {
			return <i className="fal fa-spinner fa-spin" />
		} else if (contact.avatar && contact.avatar.source_url) {
			return <img src={contact.avatar.source_url} alt="Avatar" />
		} else {
			return <i className="fal fa-user-circle" />
		}
	}

	return (
		<Form
				object={contact}
				repository={new ContactRepository()}
				redirectOnSave={true}
				router={router}
				onError={handleError}
				className="contact-form"
			>
			{
				errors.length > 0
					? <div className="error">Please enter a name for this contact.</div>
					: null
			}
			<div className="name-section">
				<label className="small"><i className="fal fa-address-card" />Full name</label>
				<TextInput name="name" data-1p-ignore className="large full" placeholder="Contact's name" autoFocus />
			</div>
			<div className="top-section">
				<div className="upload-avatar" {...getRootProps()}>
					<input {...getInputProps()} />
					<div className="default-photo">
						{showAvatar()}
					</div>
					<div className="instructions-label">
						Drag and drop or click to upload photo
					</div>
				</div>
				<div className="main-info">
					<label className="small"><i className="fal fa-info-circle" />Background or biography</label>
					<TextArea name="background" className="full four-lines" placeholder="About this contact" />
				</div>
			</div>

			<div className="other-info" style={{ margin: "10px 0 -10px 0" }}>
				<Section contact={contact} for="email_addresses" input={EmailAddressInput} name="Email addresses" icon="at" updateContact={setContact} />

				<Section contact={contact} for="employments" input={EmploymentInput} name="Employment information" icon="briefcase" updateContact={setContact} />
			</div>

			<div className="other-info">
				<h4>More about this contact</h4>

				<Section contact={contact} for="locations" input={LocationInput} name="Addresses or location" icon="map" updateContact={setContact} />

				<Section contact={contact} for="websites" input={WebsiteInput} name="Websites or links" icon="link" updateContact={setContact} />

				<Section contact={contact} for="social_profiles" input={SocialProfileInput} name="Social media profiles" icon="share-alt" updateContact={setContact} />
			</div>
			<div className="submit">
				<input type="submit" value="Save this Contact" className="rounded large green" />
			</div>
		</Form>
	)
}

export default ContactForm

/*
<Dropzone ref="drop-zone" onDrop={this.onDrop} className="upload-avatar" activeClassName="drop-zone active">
	<div className="default-photo">
		{this.avatar()}
	</div>
	<div className="instructions-label">
		Drag and drop or click to upload photo
	</div>
</Dropzone>
*/