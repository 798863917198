import React from 'react';
import { Link } from 'react-router-dom';

const ContactList = ({ organization }) => {
	const getSortedContacts = () => {
		if (organization.contacts && organization.contacts.length > 0) {
			return organization.contacts.sort((a, b) => {
				let transform = (contact) => {
					return contact.name ? `\t${contact.name.toLowerCase()}` : contact.primaryEmailAddress().toLowerCase();
				}

				return (transform(a) > transform(b) ? 1 : -1);
			})
		} else { return [] }
	}

	return (
		<div>
			{getSortedContacts().map(contact => {
				return (
					<Link key={contact.id} className="org-contact" to={`/contacts/${contact.id}`}>
						<strong>{contact.name}</strong>
						<div>{contact.title}</div>
						<div className="etc">{contact.email_address}</div>
					</Link>
				);
			})}
		</div>
	)
}

export default ContactList