import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';
import createReactClass from 'create-react-class';

export const FilterForm = createReactClass({
	getInitialState: function() {
		return {
			slice: null,
			filters: {},
			defaultFilters: {}
		};
	},
	childContextTypes: {
		valueFor: PropTypes.func,
		inputChangeHandler: PropTypes.func,
		isDefaultValue: PropTypes.func
	},
	getChildContext: function() {
		return {
			valueFor: this.valueFor,
			inputChangeHandler: this.inputChangeHandler,
			isDefaultValue: this.isDefaultValue,
		}
	},
	componentWillMount: function() {
		this.setState({ defaultFilters: {}, filters: this.props.collection.filterCriteria() });
	},
	inputChangeHandler: function(name, e) {
		var filters = this.state.filters;

		var value = (e && e.target ? e.target.value : e)

		if (value !== filters[name]) {
			if (!value) {
				filters = _.omit(filters, name);
				delete filters[name];
			} else {
				filters[name] = value;
			}

			this.setState({ filters: filters });
		}
	},
	submitHandler: function(e) {
		e.preventDefault();

		if (this.props.onSubmit) { this.props.onSubmit() }

		var collection = this.props.collection;
		collection.setFilterCriteria(Object.assign(this.state.filters, { page: 1 }));

		this.props.updater(this.state.filters, () => {
			this.props.router.push({ pathname: this.props.router.location.pathname, search: '?' + queryString.stringify(collection.getPersistentFilters()) });

			if (this.props.afterSubmit) { this.props.afterSubmit() }
		});
	},
	valueFor: function(name) {
		if (this.state.defaultFilters[name]) {
			return this.state.defaultFilters[name];
		} else {
			return _.has(this.state.filters, name) ? this.state.filters[name] : this.props.collection.getFilterCriterion(name);
		}
	},
	isDefaultValue: function(name) {
		return !!this.state.defaultFilters[name];
	},
	render: function() {
		return (
			<form onSubmit={this.submitHandler}>
				{this.props.children}
			</form>
		);
	}
});



/*
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiError from '../ApiError'
import FormContext from '../../lib/FormContext'
import { changes, getRepositoryFor } from '../../lib/Utilities'
import queryString from 'query-string';

export const eFilterForm = (props) => {
	const [valid, setValid] = useState(null)
	const [objectValid, setObjectValid] = useState(null)
	const [changedFields, setChangedFields] = useState([])
	const [submitting, setSubmitting] = useState(null)
	const [originalAttributes, setOriginalAttributes] = useState({})
	const [formFields, setFormFields] = useState([])
	const [counter, setCounter] = useState(1)
	const [slice, setSlice] = useState(null)
	const [filters, setFilters] = useState(null)
	const [defaultFilters, setDefaultFilters] = useState(null)
	const navigate = useNavigate()


	return (
		<FormContext.Provider value={{
			inputChangeHandler: inputChangeHandler,
			valueFor: valueFor,
			submitting: submitting,
			isDefaultValue: isDefaultValue
		}}>
			<form onSubmit={submitHandler} className={props.className}>
				{props.children}
			</form>
		</FormContext.Provider>
	)
}
*/


/*

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';
import createReactClass from 'create-react-class';

export const FilterForm = createReactClass({
	getInitialState: function() {
		return {
			slice: null,
			filters: {},
			defaultFilters: {}
		};
	},
	childContextTypes: {
		valueFor: PropTypes.func,
		inputChangeHandler: PropTypes.func,
		isDefaultValue: PropTypes.func
	},
	getChildContext: function() {
		return {
			valueFor: this.valueFor,
			inputChangeHandler: this.inputChangeHandler,
			isDefaultValue: this.isDefaultValue,
		}
	},
	componentWillMount: function() {
		this.setState({ defaultFilters: {}, filters: this.props.collection.filterCriteria() });
	},
	inputChangeHandler: function(name, e) {
		var filters = this.state.filters;

		var value = (e && e.target ? e.target.value : e)

		if (value !== filters[name]) {
			if (!value) {
				filters = _.omit(filters, name);
				delete filters[name];
			} else {
				filters[name] = value;
			}

			this.setState({ filters: filters });
		}
	},
	submitHandler: function(e) {
		e.preventDefault();

		if (this.props.onSubmit) { this.props.onSubmit() }

		var collection = this.props.collection;
		collection.setFilterCriteria(Object.assign(this.state.filters, { page: 1 }));
console.log('here', queryString.stringify(collection.getPersistentFilters()), this.state.filters)
console.log(this.props.updater)
		this.props.updater(this.state.filters).then(() =>{
			this.props.router.push({ pathname: this.props.router.location.pathname, search: '?' + queryString.stringify(collection.getPersistentFilters()) });
console.log('still here')
			if (this.props.afterSubmit) { this.props.afterSubmit() }
		});
	},
	valueFor: function(name) {
		if (this.state.defaultFilters[name]) {
			return this.state.defaultFilters[name];
		} else {
			return _.has(this.state.filters, name) ? this.state.filters[name] : this.props.collection.getFilterCriterion(name);
		}
	},
	isDefaultValue: function(name) {
		return !!this.state.defaultFilters[name];
	},
	render: function() {
		return (
			<form onSubmit={this.submitHandler}>
				{this.props.children}
			</form>
		);
	}
});
*/