import React from 'react';
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useCollectionLoader, useAuth, useFilterableCollection } from '../../lib/hooks'
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loading from '../common/Loading';
import Pagination from '../common/Pagination';
import { Header, PageIcon } from '../common/header';
import NoResults from '../common//NoResults';
import pluralize from 'pluralize';

const List = () => {
	const [collection, filter] = useCollectionLoader('MailingListRepository')
	const location = useLocation()
	const auth = useAuth()
	const [sort, sortForm] = useFilterableCollection(collection, filter, {
		thread_count: { icon: 'hashtag', name: 'Emails Received' },
		last_sent_at: { icon: 'calendar', name: 'Last Received' },
	})

	const frequencyDots = (list) => {
		switch (list.average_frequency_in_words) {
			case "daily":
				return <div><i className="fa fa-square" style={{ color: '#bbb' }} /><i className="fa fa-square" style={{ color: '#bbb' }} /><i className="fa fa-square" style={{ color: '#bbb' }} /></div>;
			case "weekly":
			case "twice weekly":
				return <div><i className="fa fa-square" style={{ color: '#ccc' }} /><i className="fa fa-square" style={{ color: '#ccc' }} /></div>;
			case "monthly":
			case "twice monthly":
			default:
				return <div><i className="fa fa-square" style={{ color: '#ddd' }} /></div>;
		}
	}

	const activityIndicator = (list) => {
		switch (list.status) {
			case "active":
				return <i className="fal fa-sync-alt" style={{ color: "#8cbf7e" }} title="Active" />;
			case "inactive":
				return <i className="fal fa-minus-octagon" style={{ color: "#ccc" }} title="Inactive" />;
			case "delayed":
				return <i className="fal fa-clock" style={{ color: "#e0cd22" }} title="Delayed" />;
			default:
				return null;
		}
	}

	const lists = () => {
		if (collection.isEmpty()) {
			return <NoResults message="This mailbox has not received any mailing list or notification emails." />;
		} else {
			return (
				<div>
					<div className="mailing-lists">
						{collection.records.map(list => {
							return (
								<Link className="block" to={'/mailing-lists/' + list.id} state={{ modal: true, returnTo: location.pathname }}>
									<div className="activity-indicator">{activityIndicator(list)}</div>
									<div className="mailing-list-info">
										<strong>{list.name || list.email_addresses[0].value}</strong>
										{list.unsubscribe_link ? <a href={list.unsubscribe_link} target="_blank" className="flat tertiary button invisible">unsubscribe</a> : false}
										<div className="etc">
											{
												list.interactionHistoryForUser(auth.user)
												? <><strong className="minor">{pluralize('email', list.interactionHistoryForUser(auth.user).thread_count || 0, true)}</strong>, last sent on <strong className="minor">{moment(list.interactionHistoryForUser(auth.user).first_contact).format('MMMM YYYY')}</strong></>
												: null
											}
										</div>
									</div>
									<div className="mailing-list-frequency">{frequencyDots(list)}</div>
									<div className="frequency-description">{list.average_frequency_in_words}</div>
								</Link>
							);
						})}
					</div>
					<Pagination collection={collection} filter={filter} />
				</div>
			);
		}
	}

	return (
		<div id="main" className="full centered">
			<Helmet title='Mailing lists and notifications' />
			<Header
				collection={collection}
				title={<span>Mailing Lists &amp; Notifications</span>}
			>
				<PageIcon icon="newspaper" toggleSidebarOnMobile />
			</Header>
			{collection && collection.records.length > 0 ? sortForm() : null}
			<div id="content">
				{collection ? lists() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default List

/*
export default MailboxScoper(
CollectionLoader(
	FilterableCollection(
		DefaultLensUpdater(CurrentUserAccessor(List), 'email'),
		{
			thread_count: { icon: 'hashtag', name: 'Emails Received' },
			last_sent_at: { icon: 'calendar', name: 'Last Received' },
		}
	), 'MailingListRepository'
)
);
*/