import Resource from '../lib/Resource';
import { DateTime } from 'luxon';
import TaskRepository from '../repositories/TaskRepository';

export default class Task extends Resource {
	dueDate() {
		if (this.hasDueDate()) {
			if (!this._dueDate) { this._dueDate = DateTime.fromISO(this.due, { zone: 'utc' }) }
			return this._dueDate;
		}
	}

	createdAt() {
		if (!this._createdAt) {
			this._createdAt = DateTime.fromISO(this.created_at)
		}

		return this._createdAt;
	}

	hasDueDate() {
		return !!this.due;
	}

	hasMilestone() {
		return this.milestone && Object.keys(this.milestone.attributes).length > 0;
	}

	hasAssociatedRecord() {
		return ((this.contact && this.contact.exists()) || (this.file && this.file.exists()) || (this.organization && this.organization.exists()));
	}

	associatedRecordType() {
		if (this.hasAssociatedRecord()) {
			if (this.contact.exists()) {
				return 'contact'
			} else if (this.file.exists()) {
				return 'file'
			} else if (this.organization.exists()) {
				return 'organization'
			}
		}
	}

	assignees(account) {
		return (this.assigned_user_ids || []).map(id => {
			return account.getUserById(id);
		});
	}

	category() {
		if (!this.dueDate() || this.dueDate() > DateTime.local().endOf('week')) {
			return 'later';
		} else if (this.dueDate() < DateTime.local().plus({ days: 1 }).endOf('day')) {
			return 'urgent';
		} else {
			return 'upcoming';
		}
	}

	isIncomplete() {
		return !this.isComplete();
	}

	isComplete() {
		return !!this.completed_at;
	}

	completedAt() {
		if (this.isComplete()) {
			let completedAt = (this.completed_at instanceof DateTime ? this.completed_at : DateTime.fromISO(this.completed_at))
			if (!this._completedAt) { this._completedAt = completedAt; }
			return this._completedAt;
		}
	}

	markComplete(callback = function(){}) {
		this.set('just_completed', true);

		new TaskRepository().save(this).then(callback);
	}

	markIncomplete(callback = function(){}) {
		this.set('just_completed', false);
		this.set('completed_at', null)

		new TaskRepository().save(this).then(callback);
	}

	toggleCompletion(callback = function(){}) {
		if (this.isComplete()) {
			this.markIncomplete(callback);
		} else {
			this.markComplete(callback);
		}
	}

	isAssigned() {
		return this.assigned_user_ids && this.assigned_user_ids.length > 0;
	}
}

Task.fields = [
	'id', 'assignment', 'description', 'due', 'record_type', 'record_id', 'milestone_id', 'created_at',
	'due_shortcut', 'archived', 'incomplete', 'completed_at', 'assigned_user_ids', { milestone: 'Milestone' },
	{ contact: 'Contact' }, { file: 'File' }, { organization: 'Organization' }, 'contact_id', 'file_id', 'thread_id', 'organization_id'
];
Task.validations = {
	assignment: 'present'
}
Task.modelName = 'Task';