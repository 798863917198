import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MessageBody from './MessageBody';

const Reply = (props) => {
	const attachments = () => {
		return (
			<div className="inline-attachments comma-section">
				<i className="fal fa-paperclip" />
				{props.message.attachments.map(attachment => {
					return <span className="post-comma"><Link to={`/files/${attachment.attachment_id}`}>{attachment.name || attachment.file_name}</Link> <a onClick={() => props.downloadFile(attachment.file_id, attachment.attachment_id)}><i className="fa fa-download" /></a></span>
				})}
			</div>
		);
	}

	const collapsed = () => {
		return (
			<div className={"email-reply collapsed" + (props.nextIsCollapsed ? " preceding-collapsed" : "")}>
				<div className="reply-header" onClick={props.toggleExpansion.bind(null, props.message)}>
					<i className={"toggle fa fa-caret-right"} />
					<div className="date">{moment(props.message.sent_at).format('MMMM D, YYYY [at] h:mma')}</div>
					<div className="sender">
						{props.message.hasAttachments() > 0 ? <i className="fal fa-paperclip" /> : null}
						From <strong>{props.message.from.displayName(props.message.from_email_address)}</strong>
					</div>
					<div className="snippet">{props.message.snippet}</div>
				</div>
			</div>
		);
	}

	const expanded = () => {
		return (
			<div className="email-reply">
				<div className="reply-header">
					<i className="toggle fa fa-caret-down" onClick={props.toggleExpansion.bind(null, props.message)} />
					<div className="date">{moment(props.message.sent_at).format('MMMM D, YYYY [at] h:mma')}</div>
					<div className="sender">
						From <strong><Link to={"/contacts/" + props.message.from.id}>{props.message.from.displayName(props.message.from_email_address)}</Link></strong>
					</div>
					{props.message.hasAttachments() ? attachments() : null}
				</div>
				<MessageBody message={props.message} />
			</div>
		);
	}

	return props.collapsed ? collapsed() : expanded();
}

export default Reply