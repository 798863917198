import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDefaultLens } from '../../lib/hooks'
import { Loading } from '../common/Common'
import AsyncFetcher from '../../lib/AsyncFetcher'
import SnapshotMilestones from './snapshot/Milestones'
import SnapshotTimeline from './snapshot/Timeline'
import TaskPopUnderForm from './PopUnderForm'
import { useHotkeys } from 'react-hotkeys-hook'

const Index = (props) => {
	const [loading, setLoading] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [showTaskForm, setShowTaskForm] = useState(false)
	const [snapshot, setSnapshot] = useState(null)
	const navigate = useNavigate()
	useHotkeys('n', (e) => { navigate('/tasks/new') })
	useDefaultLens('tasks')

	useEffect(() => {
		const load = async () => {
			const fetcher = new AsyncFetcher(localStorage.token)
			const json = await fetcher.get('/tasks/snapshot')

			setSnapshot(json.task_snapshot)
			setLoading(false)
			setLoaded(true)
		}

		load()
	}, [])

	const loadedView = () => {
		return (
			<div>
				<SnapshotTimeline timeline={snapshot.timeline} />

				{snapshot.pinned_milestones.length > 0 ? <SnapshotMilestones milestones={snapshot.pinned_milestones} /> : null}
			</div>
		)
	}

	return (
		<div className="main full">
			<Helmet title="Your tasks" />
			<div className="clear new-header task-index-header">
				{showTaskForm ? <TaskPopUnderForm displayToggler={() => setShowTaskForm(!showTaskForm)} popUnderClassName="right" /> : null}
				<span className="tiny rounded green button new-task-button" onClick={() => setShowTaskForm(!showTaskForm)}>Add a new task</span>
				<h1>
					<div className="page-icon"><i className="fa fa-check-circle" /></div>
					Keep track of your milestones, tasks &amp; to-do&#8217;s
				</h1>
			</div>
			<div className="main-content">
				{snapshot ? loadedView() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default Index