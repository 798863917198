import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

export const useToggle = (record, field) => {
	const [value, setValue] = useState(record ? record[field] : null)
	const queryClient = useQueryClient()

	const mapping = {
		pin: 'pinned',
		flag: 'flagged',
		read: 'read',
		follow: 'following',
		resolution: 'resolved'
	}

	const mutationFn = () => {
		return record[field]
			? console.log('toggle off')
			: console.log('toggle on')
	}

	const toggle = useMutation({
		mutationFn: mutationFn,
		onMutate: () => {
			setValue(!value)
			queryClient.setQueryData([`${record.type}/${record.id}`], { ...record, [field]: !record[field] })
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [record.type] })
		}
	})

	return { toggle, value: (record ? value : null) }
}