import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useCategoryVisibilityToggle from '../../lib/useCategoryVisibilityToggle'

const TaskSidebar = () => {
	const location = useLocation()
	const [associatedTasksVisible, associatedTasksToggle] = useCategoryVisibilityToggle('tasks.associated')
	const [dueTasksVisible, tasksDueToggle] = useCategoryVisibilityToggle('tasks.due')

	return (
		<div>
			<h2><i className="fa fa-check" /></h2>

			<NavLink to="/tasks/new" state={{ modal: true, returnTo: location.pathname, backgroundLocation: location }} className="main-action-button">Add a Task</NavLink>

			<div className="sub-panel">
				<div className="main-links">
					{/*<NavLink to="/tasks" onlyActiveOnIndex>
						<i className="far fa-dot-circle" /><span className="title">Overview</span>
					</NavLink>
					<NavLink to="/tasks/list">
						<i className="fa fa-bars" /><span className="title">Task List</span>
					</NavLink>*/}
					<NavLink to="/tasks" end>
						<i className="fa fa-check-circle" /><span className="title">Tasks</span>
					</NavLink>
					<NavLink to="/milestones">
						<i className="fa fa-map-signs" /><span className="title">Milestones</span>
					</NavLink>
				</div>

				<h3 className="section-title">
					Tasks linked to your&hellip;
					{associatedTasksToggle}
				</h3>
				<div className={"sub" + (associatedTasksVisible ? "" : " hidden")}>
					<NavLink to="/tasks/contacts">
						<i className="fal fa-user" /><span className="title">Contacts</span>
					</NavLink>
					<NavLink to="/tasks/organizations">
						<i className="fal fa-building" /><span className="title">Organizations</span>
					</NavLink>
					<NavLink to="/tasks/files">
						<i className="fal fa-file-alt" /><span className="title">Files</span>
					</NavLink>
				</div>

				<h3 className="section-title">
					Tasks by due date
					{tasksDueToggle}
				</h3>
				<div className={"sub" + (dueTasksVisible ? "" : " hidden")}>
					<NavLink to="/tasks/overdue">
						<i className="fal fa-exclamation-circle" /><span className="title">Overdue</span>
					</NavLink>
					<NavLink to="/tasks/today">
						<i className="fal fa-clock" /><span className="title">Due today</span>
					</NavLink>
					<NavLink to="/tasks/tomorrow">
						<i className="fal fa-coffee" /><span className="title">Due tomorrow</span>
					</NavLink>
					<NavLink to="/tasks/this-week">
						<i className="fal fa-ellipsis-h-alt" /><span className="title">Due this week</span>
					</NavLink>
					<NavLink to="/tasks/next-week">
						<i className="fal fa-calendar-alt" /><span className="title">Due next week</span>
					</NavLink>
					<NavLink to="/tasks/later">
						<i className="fal fa-circle" /><span className="title">Due later</span>
					</NavLink>
				</div>
			</div>
		</div>
	);
}

export default TaskSidebar;