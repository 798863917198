import { useState, useEffect } from 'react';
import { Link , useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useToggle } from '../../lib/useToggle';
import { Shareable } from '../../lib/Shareable';
import { Modalize } from '../../lib/Modalize';
import { useAuth } from '../../lib/useAuth'
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';
import ShareSheet from '../sharing/ShareSheet';
import * as FileUtilities from '../../lib/utilities/FileUtilities';
import RecordRow from '../common/RecordRow';

const Row = (props) => {
	const [file, setFile] = useState(null)
	const location = useLocation()
	const navigate = useNavigate()
	const auth = useAuth()
	const toggle = useToggle(file, setFile)
	const [showShareSheet, setShowShareSheet] = useState(false)

	useEffect(() => setFile(props.file), [])

	const sender = () => {
		if (file.getRoleOf(auth.user) === 'sender') {
			let recipients;
			if (file.recipientCount() > 1) {
				recipients = <strong className="file-sender">{`${file.recipientCount()} recipients`}</strong>
			} else {
				let recipient = file.getParticipantByEmailAddress(file.recipient_email_addresses[0])
				console.log(file, recipients)
				recipients = <Link onClick={e => { if (!window.isMobile) { e.stopPropagation() } }} to={`/contacts/${recipient.id}`} className="file-sender">{recipient.name || recipient.email_address}</Link>
			}

			return (
				<span>From <strong>You</strong> to {recipients} in {emailLink()}</span>
			);
		} else {
			let senders;
			if (file.senderCount() > 1) {
				senders = <strong className="file-sender">{`${file.senderCount()} people`}</strong>
			} else {
				let sender = file.getParticipantByEmailAddress(file.sender_email_addresses[0])
				if (sender) {
					senders = <Link onClick={e => { if (!window.isMobile) { e.stopPropagation() } }} to={`/contacts/${sender.id}`} className="file-sender">{sender.name || sender.email_address}</Link>
				} else {
					// Probably shared with us but we don't have the sender as a contact
					senders = <span className="file-sender">{file.getParticipantNameFromEmailAddress(file.sender_email_addresses[0])}</span>
				}
			}

			return (
				<span>From {senders} in {emailLink()}</span>
			);
		}
	}

	const getControls = () => {
		return [
			(auth.account.multiUser() ? {
				icon: 'fal fa-rss',
				longTitle: 'Share this file',
				shortTitle: 'Share',
				onClick: (e) => setShowShareSheet(!showShareSheet),
				persistControls: showShareSheet,
				payload: (buttonRef) => <Modalize getRefPoint={() => buttonRef} popUnderClasses="top right" show={showShareSheet}>
					<ShareSheet
						record={file}
						classNames="top right"
						toggle={() => {}/*toggleShareSheet*/}
					/>
				</Modalize>
			} : null),
			{
				icon: "fal fa-thumbtack" + (file.pinned ? " active" : ""),
				longTitle: (file.pinned ? 'Unpin this file' : 'Pin this file'),
				shortTitle: (file.pinned ? 'Unpin' : 'Pin'),
				onClick: (e) => { toggle('pin') }
			},
			{
				icon: 'fal fa-download',
				longTitle: 'Download this file',
				shortTitle: 'Download',
				onClick: download
			}
		].filter(Boolean)
	}

	const emailLink = () => {
		if (file.attachments.length > 1) {
			return <span className="file-thread">{file.attachments.length} threads</span>
		} else if (file.attachments.length === 1) {
			return <Link onClick={e => { if (!window.isMobile) { e.stopPropagation() } }} to={{ pathname: `/email/${file.attachments[0].thread.id}`, state: { modal: true, returnTo: location.pathname } }} className="file-thread">{file.attachments[0].thread.subject || '(No subject)'}</Link>;
		}
	}

	const messageInfo = () => {
		if (file.attachment_count > 1) {
			return <span>Included in <span className="attachment-count">{file.attachment_count} emails</span></span>;
		} else {
			return file.attachments[0].thread.subject;
		}
	}

	const download = () => {
		window.open(file.downloadUrl(), "_blank");
	}

	if (file) {
		return (
			<RecordRow
				link={{ pathname: file.getPath() }}
				controls={getControls()}
			>
				{false && props.shareSheet(file, "right side")}
				<div className={"file-icon " + FileUtilities.fileType(file)}>{FileUtilities.fileIcon(file)}</div>
				<div className="row-body">
					<div className="date">{moment(file.last_sent_at).format('MMMM D, YYYY')}</div>
					<strong className="minor file-name" style={{ fontSize: "14px" }} title={file.name ? file.file_name : null}>{file.pinned ? <i className="fa fa-thumbtack" /> : null}{file.displayName()}</strong> <span className="etc inline">{file.lastFileSize() ? <span><i className="far fa-hdd" /> {FileUtilities.bytesToHumanSize(file.lastFileSize())}</span> : null}</span>
					<div className="etc minor strong" style={{ color: "#aaa" }}>
						<div className="file-info">
							{sender()}
						</div>
					</div>
				</div>
			</RecordRow>
		)
	}
}

export default Row