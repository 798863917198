import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth, useCollectionLoader } from '../../lib/hooks'
import Fetcher from '../../lib/Fetcher'
import Connection from '../mailboxes/Connection'
import { Loading } from '../common/Common'
import MailboxRepository from '../../repositories/MailboxRepository'
import InitialMailboxSubscriptionPicker from './InitialMailboxSubscriptionPicker'

const Initialize = () => {
	const auth = useAuth()
	const [mailbox, setMailbox] = useState(null)
	const [mailboxLoaded, setMailboxLoaded] = useState(false)
	const [showSubscribable, setShowSubscribable] = useState(!auth.user.canConnectMailbox())
	const fetcher = new Fetcher(localStorage.token)
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const [collection, filter] = useCollectionLoader('MailboxRepository', {}, () => {})

	useEffect(() => {
		if (auth.user.canConnectMailbox()) {
			let repo = new MailboxRepository()

			repo.find(auth.user.primaryMailbox().id).then(mailbox => {
				if (!mailbox.authorized && queryParams.get('state') && queryParams('code')) {
					mailbox.configuration.oauth_code = queryParams('code')

					repo.save(mailbox).then(mailbox => {
						setMailbox(mailbox)
						setMailboxLoaded(true)
					})
				} else {
					setMailbox(mailbox)
					setMailboxLoaded(true)
				}
			})
		} else {
			setMailboxLoaded(true)
		}
	}, [])

	const main = () => {
		if (showSubscribable) {
			return <InitialMailboxSubscriptionPicker user={auth.user} account={auth.account} finalize={finalize} location={location} />
		} else {
			return <Connection mailbox={mailbox} />
		}
	}

	const importing = () => {
		return (
			<div>
				<div className="connected-importing">
					<i className="fa fa-check-circle" />
					<h2>Your mailbox is connected!</h2>
					<p>We&#8217;ve started importing your emails, files, and contacts.  You can start using Carom while we&#8217;re working.</p>
				</div>
			</div>
		)
	}

	const finalize = (subscriptions) => {
		let attributes = { initialized: true, mailbox_subscriptions: [] }
		if (subscriptions && subscriptions.length > 0) {
			subscriptions.forEach(subscription => {
				attributes.mailbox_subscriptions.push({ mailbox_id: subscription.mailbox_id, color: subscription.color })
			})
		}

		fetcher.patch(`/users/${auth.user.id}`, { user: attributes }).then(() => auth.loadUser())
	}

	const nextSteps = () => {
		if (mailbox && mailbox.authorized) {
			if (collection && collection.records.length > 1) {
				return <span className="medium green rounded button" onClick={() => setShowSubscribable(true)}>Subscribe to other mailboxes &rarr</span>
			} else {
				return <span className="medium green rounded button" onClick={finalize}>Get started &rarr</span>
			}
		}
	}

	return (
		<div className="main mini">
			<div className="subheading">
				<i className="fa fa-inbox" />
				<h2>{showSubscribable ? <span>Connect to existing mailboxes</span> : "Connect your mailbox"}</h2>
				<a to="/" className="header-side-link">skip for now</a>
			</div>
			<div className="main-content">
				{mailboxLoaded /*&& props.loaded*/ ? main() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default Initialize