import { useState, useEffect } from 'react';
import { NavLink, useOutletContext, useLocation } from 'react-router-dom';
import useCategoryVisibilityToggle from '../../lib/useCategoryVisibilityToggle'
import { useAuth } from '../../lib/useAuth'

const useSidebarFilterLink = () => {
	const [loading, setLoading] = useState(null)
	const location = useLocation()

	useEffect(() => {
		if (loading == location.pathname) {
			setLoading(null)
		}
	}, [location.pathname])

	const updateLoading = (linkTo) => {
		if (linkTo !== location.pathname) {
			setLoading(linkTo)
		}
	}

	const filterLink = (linkTo, name, icon) => {
		return (
			<NavLink to={linkTo} onClick={() => updateLoading(linkTo)}>
				<i className={loading == linkTo ? 'fal fa-spinner-third fa-spin' : `fal fa-${icon}`} /><span className="title">{name}</span>
			</NavLink>
		)
	}

	return filterLink
}

const FileSidebar = () => {
	const context = useOutletContext()
	const [smartFilesVisible, smartFilesToggle] = useCategoryVisibilityToggle('files.smart')
	const [fileTypesVisible, fileTypesToggle] = useCategoryVisibilityToggle('files.type')
	const [recentFilesVisible, recentFilesToggle] = useCategoryVisibilityToggle('files.recent')
	const filterLink = useSidebarFilterLink()
	const auth = useAuth()

	const recentlyViewed = () => {
		if (auth.user.getViewHistory().getLatest('files').length === 0) { return null; }

		return (
			<div>
				<h3 className="section-title">
					Recently viewed
					{recentFilesToggle}
				</h3>
				<div className={"sub" + (recentFilesVisible ? "" : " hidden")}>
					{auth.user.getViewHistory().getLatest('files').map(fileView => {
						return (
							<NavLink key={"recent." + fileView.id} to={"/files/" + fileView.id}>
								<span className="record-name title">{fileView.name || fileView.file_name}</span>
							</NavLink>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<div>
			<h2><i className="fal fa-file-alt" /></h2>
			<div className="sub-panel">
				<div className="main-links">
					<NavLink to="/files" end>
						<i className="far fa-dot-circle" /><span className="title">Overview</span>
					</NavLink>
					<NavLink to="/files/list">
						<i className="fa fa-bars" /><span className="title">Files</span>
					</NavLink>
					<NavLink to="/files/search">
						<i className="fa fa-search" />
						{/*<input type="text" value="Search your files..." />*/}
						<span className="title">Search files</span>
					</NavLink>
				</div>

				<h3 className="section-title">
					Smart file filters
					{smartFilesToggle}
				</h3>
				<div className={"sub" + (smartFilesVisible ? "" : " hidden")}>
					{
						auth.account.multiUser()
						? (<NavLink to="/files/shared">
								<i className="fal fa-rss" /><span className="title">Shared with you</span>
							</NavLink>)
						: null
					}
					<NavLink to="/files/pinned">
						<i className="fal fa-thumbtack" /><span className="title">Pinned files</span>
					</NavLink>
					{filterLink('/files/tasked', 'Files with open tasks', 'check-circle')}
					{false && (<NavLink to="/files/pinned-contacts">
						<i className="fal fa-user-circle" /><span className="title">With pinned contacts</span>
					</NavLink>)}
					{filterLink('/files/from-you', 'Sent by you', 'reply')}
					{filterLink('/files/to-you', 'Sent to you', 'share')}
				</div>

				<h3 className="section-title">
					Files by type
					{fileTypesToggle}
				</h3>
				<div className={"sub" + (fileTypesVisible ? "" : " hidden")}>
					<NavLink to="/files/images">
						<i className="fal fa-file-image" /><span className="title">Images</span>
					</NavLink>
					<NavLink to="/files/documents">
						<i className="fal fa-file-alt" /><span className="title">Documents</span>
					</NavLink>
					<NavLink to="/files/spreadsheets">
						<i className="fal fa-file-excel" /><span className="title">Spreadsheets</span>
					</NavLink>
					<NavLink to="/files/presentations">
						<i className="fal fa-file-powerpoint" /><span className="title">Presentations</span>
					</NavLink>
					<NavLink to="/files/pdfs">
						<i className="fal fa-file-pdf" /><span className="title">PDFs</span>
					</NavLink>
					<NavLink to="/files/other">
						<i className="fal fa-file" /><span className="title">Other</span>
					</NavLink>
				</div>

				{recentlyViewed()}
			</div>
		</div>
	)

}

export default FileSidebar