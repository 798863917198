import React from 'react'
import { useRouter } from '../../lib/useRouter'
import UserRepository from '../../repositories/UserRepository'
import { Form } from '../../lib/forms/Form'
import { BooleanToggle } from '../../lib/forms/BooleanToggle'
import { InvalidMessage } from '../../lib/forms/InvalidMessage'
import { ErrorMessage } from '../../lib/forms/ErrorMessage'
import { Submit } from '../../lib/forms/Submit'
import { TextInput } from '../../lib/forms/TextInput'
import { useAuth } from '../../lib/useAuth'

const UserForm = ({ user, addNewUser }) => {
	const auth = useAuth()
	const router = useRouter()

	const ownerRow = () => {
		if (auth.user.isOwner()) {
			return (
				<>
					<div className="option">
						<i className="fa fa-credit-card" />
						<div>
							<label>Is this user an <strong>account owner</strong>?</label>
							<p className="etc normal-size">Owners can manage this account&#8217;s billing settings, including canceling your subscription.</p>
						</div>
						<BooleanToggle name="is_owner" />
					</div>
				</>
			)
		}
	}

	return (
		<Form className={"gray main-form user-form-container " + (user.id ? 'edit-user-form' : 'new-user-form')} object={user} onSave={user.id ? null : addNewUser} repository={new UserRepository()} router={router} redirectOnSave={true} redirectUrl="/settings/users">
			<InvalidMessage text="Please provide a name and a valid email address for this user." />
			<ErrorMessage />
			<div className="required-info">
				<TextInput name="first_name" autoFocus data-1p-ignore className="large name" placeholder="First name" />
				<TextInput name="last_name" className="large name" data-1p-ignore placeholder="Last name" />
				{user.id
					? null
					: <TextInput name="email_address" data-1p-ignore className="large email-address" placeholder="Email address" />
				}
			</div>
			<div className="user-options">
				<div className="option">
					<i className="fa fa-inbox" />
					<div>
						<label>Allow this user to <strong>connect email accounts</strong>?</label>
						<p className="etc normal-size">Otherwise the user will only be able to access existing mailboxes connected to this account.</p>
					</div>
					<BooleanToggle name="can_connect_email_account" />
				</div>
				<div className="option">
					<i className="fa fa-key" />
					<div>
						<label>Is this user an <strong>account administrator</strong>?</label>
						<p className="etc normal-size">Administrators can add mailboxes and users, but not necessarily manage billing options.</p>
					</div>
					<BooleanToggle name="is_admin" />
				</div>
				{ownerRow()}
			</div>
			<div className="submit">
				<Submit name="send" value={user.id ? "Update this user" : "Create this user"} className="rounded medium green" />
			</div>
		</Form>
	)
}

export default UserForm