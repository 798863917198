import React, { useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { useAuth } from '../../../lib/useAuth'
import { useResourceLoader } from '../../../lib/useResourceLoader'
import { Link } from 'react-router-dom';
import { Loading } from '../../common/Common';

const Share = () => {
	const contact = useResourceLoader('ContactRepository')
	const auth = useAuth()

	const body = () => {
		return (
			<>
				<div className="info" style={{ background: '#f2f2f2', borderBottom: '0' }}>
sdf
				</div>
				<div className="contact-share-list">
					{auth.account.users.filter(user => user.id !== auth.user.id).map(user => {
						return (
							<div className="contact-share-row">
								<strong>{user.first_name} {user.last_name}</strong>
								<ul className="multipart-console">
									<li><span className="console-button">Contact only</span></li>
									<li><span className="console-button">Emails and files</span></li>
								</ul>
							</div>
						)
					})}
				</div>
			</>
		)
	}

	return (
		<div className="main small standalone">
			<Helmet title="Share" />
			<div className="subheading">
				<i className="fa fa-rss" />
				<h2>Share this contact</h2>
				{contact ? <Link to={`/contacts/${contact.id}`} className="close"><i className="fa fa-times" /></Link> : null}
			</div>
			<div className="main-content">
				{contact ? body() : <Loading inline />}
			</div>
		</div>
	)
}

export default Share