import React from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import useCategoryVisibilityToggle from '../../lib/useCategoryVisibilityToggle'
import { useAuth } from '../../lib/useAuth'

const OrganizationSidebar = () => {
	const [smartOrganizationsVisible, smartOrganizationsToggle] = useCategoryVisibilityToggle('organizations.smart')
	const [recentOrganizationsVisible, recentOrganizationsToggle] = useCategoryVisibilityToggle('organizations.smart')
	const auth = useAuth()

	const recentlyViewed = () => {
		if (auth.user.getViewHistory().getLatest('organizations').length === 0) { return null; }

		return (
			<div>
				<h3 className="section-title">
					Recently viewed
					{recentOrganizationsToggle}
				</h3>
				<div className={"recent-organizations sub" + (recentOrganizationsVisible ? "" : " hidden")}>
					{auth.user.getViewHistory().getLatest('organizations').map(organizationView => {
						return (
							<NavLink key={"recent." + organizationView.id} to={"/organizations/" + organizationView.id}>
								<span className="record-name title"><span>{organizationView.name}</span></span>
							</NavLink>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<div>
			<h2><i className="fa fa-building" /></h2>
			<div className="sub-panel">
				<div className="main-links">
					<NavLink to="/organizations" end>
						<i className="far fa-dot-circle" /><span className="title">Overview</span>
					</NavLink>
					<NavLink to="/organizations/list">
						<i className="fa fa-bars" /><span className="title">Organizations</span>
					</NavLink>
					<NavLink to="/organizations/search">
						<i className="fa fa-search" />
						<span className="title">Search organizations</span>
					</NavLink>
				</div>

				<h3 className="section-title">
					Smart filters
					{smartOrganizationsToggle}
				</h3>
				<div className={"sub" + (smartOrganizationsVisible ? "" : " hidden")}>
					<NavLink to="/organizations/pinned">
						<i className="fal fa-thumbtack" /><span className="title">Pinned organizations</span>
					</NavLink>
					<NavLink to="/organizations/contacted">
						<i className="fal fa-comment" /><span className="title">You&#8217;ve contacted</span>
					</NavLink>
					<NavLink to="/organizations/tasked">
						<i className="fal fa-check-circle" /><span className="title">With tasks</span>
					</NavLink>
					<NavLink to="/organizations/enriched">
						<i className="fal fa-plug" /><span className="title">Enriched records</span>
					</NavLink>
				</div>

				{recentlyViewed()}
			</div>
		</div>
	)
}

export default OrganizationSidebar