import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types'
import { BrowserRouter, Routes, Route, Outlet, useOutletContext, useNavigate } from 'react-router-dom'
import { Routable } from '../lib/Utilities'
import _ from 'lodash'
import User from '../resources/User'
import InitializeUser from './users/Initialize'
import AppError from './common/AppError'
import { Navigator } from './structure/Navigator'
import Loading from './common/Loading'
import NewSession from './sessions/New'
import moment from 'moment-timezone'
import WebsocketHandler from '../lib/WebsocketHandler'
import * as Sessions from './sessions/Sessions'
import * as Tasks from './tasks/Tasks'
import * as Files from './files/Files'
import * as Passwords from './passwords/Passwords'
import * as Users from './users'
import * as Mailboxes from './mailboxes/Mailboxes'
import * as Accounts from './accounts/Accounts'
import ProtectedPage from './common/ProtectedPage'

import { useAuth, AuthProvider } from '../lib/useAuth'

const Carom = () => {
	const auth = useAuth()
	const queryClient = new QueryClient();

	return (
		<div id="skeleton" className={"anonymous"}>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<Routes>
						<Route path="sessions" element={<Sessions.New />} />
						<Route path="reset-password" element={<Passwords.ResetRequest />} />
						<Route path="reset-password/:token" element={<Passwords.Reset />} />
						<Route path="users/:id/activate/:activation_code" element={<Users.Activate />} />
						<Route path="mailboxes/new" element={<Mailboxes.New />} />
						<Route path="accounts/new" element={<Accounts.New />} />
						<Route path="*" element={<ProtectedPage />} />
					</Routes>
				</AuthProvider>
			</QueryClientProvider>
		</div>
	)
}

export default Carom