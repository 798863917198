import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Workspace } from './Organization';
import ScopedThreads from '../threads/ScopedList'
import ScopedFiles from '../files/ScopedList'
import moment from 'moment';
import { Enrichable } from '../../lib/Enrichable';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';
import Loading from '../common/Loading'
import { Modalize } from '../../lib/Modalize';
import NewTaskForm from '../tasks/New';
import { useHotkeys } from 'react-hotkeys-hook'
//import { useOrganizationLoader } from './useOrganizationLoader'
//import { useToggle } from '../../lib/useToggle'
import { useQuery } from 'react-query'
import { getOrganization } from '../../lib/api/OrganizationRequests'
import { useToggle } from '../../lib/api/SharedRequests'

const View = (props) => {
	const location = useLocation()
	const { id } = useParams()
	const match = location.pathname.match(/\/organizations\/[a-z0-9]+\/(.*)\/?/i)
	const section = match && ['emails', 'files'].includes(match[1]) ? match[1] : 'workspace'
	const [active, setActive] = useState(section)
	const { data: organization, isLoading, error } = useQuery(getOrganization(id))

	const navigate = useNavigate()
	const { toggle, value: pinned } = useToggle(organization, 'pinned')
	useHotkeys('e', () => { navigate(`/organizations/${organization.id}/edit`) })
	useHotkeys('d', () => { navigate(`/organizations/${organization.id}/delete`) })
//	useHotkeys('p', () => { props.toggle('pin') })

	if (isLoading) { return <Loading /> }

	const activePanel = () => {
		switch (active) {
			case 'emails':
				return <ScopedThreads key={`${organization.id}.threads`} panelSwitcher={setActive} organization={organization} scopedMailbox={props.scopedMailbox} location={location} />;
			case 'files':
				return <ScopedFiles key={`${organization.id}.files`} panelSwitcher={setActive} organization={organization} scopedMailbox={props.scopedMailbox} location={location} router={props.router} />;
			default:
				return <Workspace key={`${organization.id}.workspace`} panelSwitcher={setActive} organization={organization} scopedMailbox={props.scopedMailbox} location={location}  />;
		}
	}

	const mobileNav = () => {
		let types = {
			emails: {
				name: 'Emails',
				icon: 'envelope',
				count:  organization.thread_count.toLocaleString()
			},
			files: {
				name: 'Files',
				icon: 'file-alt',
				count:  organization.file_count.toLocaleString()
			}
		};

		return (
			<div className="subheader-nav">
				<ul className="sections">
					<li><span className={"console-button" + (active === "workspace" ? " active" : "")} onClick={() => setActive("workspace")}><i className="fal fa-bullseye" />Snapshot</span></li>
					{Object.keys(types).map(k => {
						return (
							<li key={k}>
								<span className={"console-button" + (active === k ? ' active' : '')} onClick={() => setActive(k)}>
									<i className={`fal fa-${types[k].icon}`} />
									{types[k].name}
									{types[k].count && types[k].count > 0 ? <span className="count">{types[k].count}</span> : null}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	return (
		<div className="main full organization">
			<Helmet title={organization.name} />
			<Header
				breadcrumb={<><strong>organization</strong>{organization.due ? <span className="due-date">{moment(organization.due).format('dddd, MMMM D')}</span> : null}</>}
				title={<span>{organization.name}{organization.enriched ? <i className="fa fa-plug enriched-indicator" title="Enriched record" /> : null}</span>}
			>
				{
					organization.logo.length > 0
					? <PageIcon imgSrc={organization.logo.source_url} className="logo" />
					: <PageIcon icon="building" />
				}
				<HeaderActions>
					{organization.has_duplicates && <HeaderAction linkTo={{ pathname: `/${props.contact ? 'contacts' : 'organizations'}/${organization.id}/duplicates`, state: { organization: organization, modal: true, returnTo: location.pathname } }} icon="clone" title="Resolve duplicates" />}
					{false && props.getEnrichmentActionButton()}
					<HeaderAction icon="check-circle" title="Add a task">
						<Modalize popUnderClasses="top right headingless">
							{<NewTaskForm record={organization} />}
						</Modalize>
					</HeaderAction>
					<HeaderAction onClick={() => toggle.mutate('pin')} icon="thumbtack" className={pinned ? "pinned" : ""} title={organization.pinned ? "Unpin this organization" : "Pin this organization"} />
					<HeaderAction icon="pencil" title="Edit this organization" linkTo={{ pathname: "/organizations/" + organization.id + "/edit", state: { resource: organization, modal: true, backgroundLocation: location } }} />
					<HeaderAction icon="trash" title="Delete this organization" linkTo={{ pathname: "/organizations/" + organization.id + "/delete", state: { resource: JSON.stringify(organization), modal: true, returnTo: location.pathname, backgroundLocation: location } }} />
				</HeaderActions>
			</Header>

			{mobileNav()}

			<div id="organization" className="main-content">
				{activePanel()}
			</div>
		</div>
	)
}

//export default Enrichable(View);

export default View