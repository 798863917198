import React from 'react';
import { CurrentUserAccessor } from '../../../../lib/CurrentUserAccessor';
import Contact from '../../../../resources/Contact'

const Section = (props) => {
	const addBlank = () => {
		let contact = props.contact;
		contact.getOrBuildAssociation(props.for)

		props.updateContact(new Contact({ ...contact }))
	}

	const updateAssociated = (association, updated) => {
		let contact = props.contact;
		contact[association].filter(existing => existing.lookupKey() !== updated.lookupKey())
		contact[association].concat(updated)

		props.updateContact(contact);
	}

	const removeAssociated = (association, record) => {
		let contact = props.contact;
		contact[association].find(existing => existing.lookupKey() === record.lookupKey()).deleted = true;

		props.updateContact(contact);

	}

	let singular = (props.for === "email_addresses" ? "email_address" : props.for.slice(0, -1));
	let inputProps = (input) => {
		let newProps = {
			remove: removeAssociated,
			update: updateAssociated,
			contact: props.contact,
			association: input,
			updateContact: props.updateContact
		}
		newProps[singular] = input;
		return newProps;
	}

	let i = 0
	let inputs = props.contact[props.for].filter(assoc => !assoc.deleted).map(input => {
		i += 1
		return React.createElement(props.input, { key: `${props.for}.${i}`, ...inputProps(input) })
	})

	return (
		<div className="section" key={props.for}>
			<h5>
				<i className={`fal fa-${props.icon}`} />
				{props.name}
				<span className="add-another" onClick={() => addBlank()}>add another</span>
			</h5>
			{inputs}
		</div>
	)
}

export default Section