import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/useAuth'
import DraftForm from '../drafts/Form';
import Draft from '../../resources/Draft';
import { uniqBy } from 'lodash';

const NewThreadMessage = (props) => {
	const [email, setEmail] = useState(null)
	const [type, setType] = useState(props.type)
	const [replyRecipients, setReplyRecipients] = useState([])
	const [forwardRecipients, setForwardRecipients] = useState([])
	const auth = useAuth()

	useEffect(() => {
		if (!email) {
			let draft = new Draft({
				thread_id: props.referenceThread.id,
				to_recipient_ids: replyRecipients.map(recipient => recipient.id)
			});

			setReplyRecipients(draft.to_recipients)

			setEmail(draft)
		}
	}, [])

/*
	const replyRecipients = () => {
		if (!this._replyRecipients) {
			this._replyRecipients = {
				to_recipients: uniqBy(props.referenceMessage.to.concat(props.referenceMessage.from).filter(sender => {
					return sender.email_address !== props.currentUser.email_address;
				}), 'email_address')
			}
		}

		return this._replyRecipients;
	}

	forwardRecipients = () => {
		if (!this._forwardRecipients) {
			this._forwardRecipients = { to_recipients: [] }
		}

		return this._forwardRecipients;
	}
*/

	const getRecipientsForAction = (action) => {
		if (action == 'reply') {
			return replyRecipients
		} else if (action == 'forward') {
			return forwardRecipients
		}
	}

	const typeSetter = (type, currentDraft) => {
		let newEmail = email
		newEmail = Object.assign(email, getRecipientsForAction(type));
		newEmail.draft_type = type;

		setType(type)
		setEmail(new email.constructor(newEmail))
	}

	const setFormRef = (ref) => {
		this.draftForm = ref;
	}

	return (
		<div className={"add-thread-message" + (type ? ' loaded' : ' inactive')}>
			<div className="message-type-tabs">
				<div className={"draft-reply" + (type === "reply" ? " active" : "")} onClick={() => typeSetter('reply')}><i className="fal fa-reply" />Reply to this email</div>
				<div className={"draft-forward" + (type === "forward" ? " active" : "")} onClick={() => typeSetter('forward')}>Forward this email<i className="fal fa-share" /></div>
			</div>
			{type ? <DraftForm email={email} referenceMessage={props.referenceMessage} setFormRef={setFormRef} type={type} updateType={typeSetter} /> : null}
		</div>
	)
}

export default NewThreadMessage